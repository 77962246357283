<template>
  <!-- modal -->
  <b-modal
    :id="modalData.modalWithdrawalId"
    :hide-footer="true"
    :dialog-class="'withdraw-modal'"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #default>
      <div class="withdraw-modal-header">
        <span class="box-card-icon icon-1" />
        <span class="withdraw-modal-title">
          {{ withdraw.vestingPool.stakeTokenSymbol + ' ' + modalData.withdrawTitle }}
        </span>
      </div>
      <p class="withdraw-modal-note">
        *Investing and you will be rewarded with {{ withdraw.vestingPool.stakeTokenSymbol }}.
      </p>
      <div class="modal-input-group mt-4">
        <span
          class="input-note"
        >{{ toFormattedToken(available) + ' ' + withdraw.vestingPool.stakeTokenSymbol }} Available</span>
        <input
          :value="toFormattedToken(available)"
          placeholder="0"
          type="text"
          class="form-control modal-input"
          readonly
        >
      </div>
      <div class="modal-group-data">
        <div class="modal-group-data-label">
          Earn:
        </div>
        <div class="modal-group-data-value">
          {{ withdraw.vestingPool.stakeTokenSymbol }}
        </div>
      </div>
      <div class="withdraw-modal-buttons">
        <button class="btn btn-none-active btn-cancel font-weight-bold" @click="hideModal()">
          Cancel
        </button>
        <button
          class="btn btn-none-active btn-confirm position-relative font-weight-bold"
          :class="loadingClass"
          :disabled="isLoading"
          @click="onConfirm"
        >
          {{ isLoading ? 'Confirming' : 'Confirm' }}
        </button>
      </div>
    </template>
  </b-modal>
  <!-- end modal -->
</template>

<style lang="scss" scoped>
.text-description {
  color: #637281;
  font-size: 13px;

  @media (max-width: 414px) {
    font-size: 11px;
  }
}
</style>

<script>
import BaseMixin from '@/mixins/base';
import { getPools } from '@/utils/vesting';
import vestingPools from '@/domain/vesting/pools';

export default {
  name: 'WithdrawModal',

  mixins: [BaseMixin],

  props: {
    modalData: {
      type: Object,
      default() {
        return {
          modalWithdrawalId: '',
          withdrawTitle: '',
        };
      },
    },
    withdrawalTokens: {
      type: String,
      default: `${0}`,
    },
    loadingClass: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      isLoading: false,
      checkStatusInterval: null,
    };
  },

  computed: {
    tokensAmount() {
      return this.withdrawalTokens.replaceAll(',', '');
    },

    available() {
      return this.web3.instance.utils.toBN(this.withdraw.vestingPool?.account?.claimable || '0').toString();
    },

    withdraw() {
      return this.$store.state.withdraw;
    },
  },

  methods: {
    hideModal() {
      this.$emit('onHideModal', this.modalData.modalWithdrawalId);
    },
    onConfirm() {
      this.isLoading = true;
      this.$emit('onSetClasses', this.isLoading);

      const abi = vestingPools.find(
        (pool) => pool.contractAddress == this.withdraw.vestingPool.stakeContractAddress
      ).abi;

      const vestingPoolContract = new this.web3.instance.eth.Contract(
        abi,
        this.withdraw.vestingPool.stakeContractAddress
      );

      vestingPoolContract.methods
        .claim()
        .send({ from: this.account.address })
        .on('transactionHash', (transactionHash) => {
          this.checkTransactionStatus(transactionHash, 10);
        })
        .on('error', () => {
          this.incompleteTransaction();
        });
    },
    checkTransactionStatus(transactionHash, seconds) {
      this.checkStatusInterval = setInterval(() => {
        this.web3.instance.eth.getTransactionReceipt(transactionHash).then((result) => {
          if (result) {
            if (result.status) {
              clearInterval(this.checkStatusInterval);
              this.completeTransaction();
            }
          }
        });
      }, seconds * 1000);
    },
    completeTransaction() {
      let amount =
        Number(this.tokensAmount) +
        Number(this.convertWeiToEther(this.account.balance).toString());

      this.$store.dispatch('account/increaseBalance', this.convertEtherToWei(amount.toString()));

      getPools(this.web3.instance, this.account.address).then((result) => {
        this.$store.dispatch('vesting/updatePools', result);

        this.isLoading = false;
        this.$emit('onHideModal', this.modalData.modalWithdrawalId);
        this.$emit('onSetClasses', this.isLoading);
        this.$emit('resetWithdrawalTokens', `${0}`);

        this.$root.$emit('global-notification', {
          title: 'Unstaked!',
          type: 'success',
          content: `Your ${this.withdraw.vestingPool.stakeTokenSymbol} earnings have also been harvested to your wallet!`,
        });
      });
    },
    incompleteTransaction() {
      this.isLoading = false;
      this.$emit('onSetClasses', this.isLoading);

      this.$root.$emit('global-notification', {
        title: 'Error',
        type: 'error',
        content: 'Please try again. Confirm the transaction and make sure you are paying enough gas!',
      });
    },
  },
};
</script>
